











































































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";

import Rules from "@/ab-tests/components/Rules.vue";
import TargetedConfigSettings from "@/ab-tests/components/TargetedConfigSettings.vue";
import { RuleOperator } from "@/ab-tests/models/RuleModel";
import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import TargetedConfigStore from "@/ab-tests/store/TargetedConfigStore";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    Rules,
    TargetedConfigSettings,
  },
})
export default class TargetedConfigurationNewView extends mixins(
  UnsavedChangesMixin
) {
  store: TargetedConfigStore = this.$store.state.targetedConfig;
  step = 1;
  targetedConfig: TargetedConfigurationModel = new TargetedConfigurationModel();
  existingNames: Array<string> = [];

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return Object.assign({}, this.targetedConfig);
  }

  get maxDateInRules() {
    return ConfigUtil.maxDateInRules(this.targetedConfig.rules);
  }

  get hasOnlyExcludeRules(): boolean {
    return (
      !!this.targetedConfig.rules.length &&
      this.targetedConfig.rules.length ===
        this.targetedConfig.rules.filter(
          ({ operator }) => operator === RuleOperator.EXCLUDE
        ).length
    );
  }

  get disabledSave(): boolean {
    return (
      (this.step === 1 &&
        !(
          this.targetedConfig.name &&
          this.existingNames.indexOf(this.targetedConfig.name.toLowerCase()) ===
            -1
        )) ||
      (this.step === 2 &&
        (!this.targetedConfig.rules.length || this.hasOnlyExcludeRules))
    );
  }

  get defaultConfig() {
    return this.$store.state.defaultConfig.config;
  }

  created() {
    this.existingNames = this.$store.getters["targetedConfigNames"];
    this.handleWatchingUnsavedChanges();
  }

  updated() {
    this.existingNames = this.$store.getters["targetedConfigNames"];
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  save() {
    if (this.step === 2) {
      this.targetedConfig.parentId = this.defaultConfig.id;
      this.$store
        .dispatch("createTargetedConfig", this.targetedConfig)
        .then((response) => {
          this.isSavedForm = true;
          this.$router.push({
            name: "targeted_edit_configs",
            params: {
              configId: response?.id,
            },
          });
        });
    } else {
      this.step++;
    }
  }

  cancel() {
    if (this.step === 1) {
      this.$router.push({
        name: AppSection.TARGETED_CONFIGS,
      });
    } else {
      this.step--;
    }
  }
}
